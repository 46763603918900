/* eslint react/prop-types: 0 */
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from './layout';
import SEO from './seo';

class Person extends React.Component {
	render() {
		return (
			<Layout>
				<SEO
					keywords={[
						`Physiotherapie`,
						`Wien`,
						`1010`,
						`movendos`,
						`Manualtherapie`,
						`Manuelle Therapie`,
						this.props.person.name
					]}
					title={this.props.person.name}
				/>
				<div className="flex flex-wrap">
					<div className="w-1/2 md:w-1/4 p-4">
						<Img fluid={this.props.img} alt={this.props.person.name} />
					</div>
					<div className="p-4 w-full md:w-3/4">
						<div className="w-full p-4 bg-gray-200">
							<h1 className="text-primary text-xl mb-4">{this.props.person.name}</h1>
							{ this.props.person.bookURL !== undefined && 
							<div className="text-primary text-lg mb-2">
								<a href={this.props.person.bookURL}>Online-Terminvereinbarung</a>
							</div>
							}
							{ this.props.person?.karenz ? <div className="text-lg mb-4">{this.props.person.karenz}</div> : 
							<div className="text-primary text-lg">
								<a href={'mail'.concat('to:', this.props.person.email.props.children)}>
									{this.props.person.email}
								</a>
							</div>
							}
							{ !this.props.person?.karenz && <div className="text-primary text-lg mb-4">
								<a href={`tel:${this.props.person.phone}`}>{this.props.person.phone}</a>
							</div> }
							{this.props.content.map((entry, key) => (
								<div key={key}>
									{entry[0] && <h2 className="font-medium">{entry[0]}</h2>}
									<p className="mb-4">{entry[1]}</p>
								</div>
							))}
							<h2 className="text-primary mt-4 mb-2">Aus-und Weiterbildung</h2>

							{this.props.cvs[0].map((entry, key) => {
								return (
									<div key={key} className="flex flex-wrap mb-2">
										<div className="font-medium pr-4 w-32">
											{entry[0]}
										</div>
										<div className="flex-1 min-w-7/12">{entry[1]}</div>
									</div>
								);
							})}

							<h2 className="text-primary mt-4 mb-2">Beruflicher Werdegang</h2>
							{this.props.cvs[1].map((entry, key) => {
								return (
									<div key={key} className="flex flex-wrap mb-2">
										<div className="font-medium pr-4 w-32">
											{entry[0]}
										</div>
										<div className="flex-1 min-w-7/12">{entry[1]}</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export const personImage = graphql`
	fragment PersonImage on File {
		childImageSharp {
			fluid(maxWidth: 680) {
				...GatsbyImageSharpFluid_withWebp_tracedSVG
			}
		}
	}
`;

export default Person;
