/* eslint react/prop-types: 0 */
import React from 'react';
import { graphql } from 'gatsby';
import { dataJulia } from '../hooks/dataPersons';
import Person from '../components/person';

function Julia({ data }) {
	const personImage = data.file.childImageSharp.fluid;
	const cv = [
		[ '2015-2020', 'Manuelle Therapie nach dem Maitland-Konzept' ],
		[ '2015', `Sportphysiotherapeutische Behandlung der Schulter (ESP\u2011Wien)` ],
		[ '2015', 'Therapeutisches Klettern' ],
		[ '2011-2014', 'FH Campus Wien – Bachelorstudium Physiotherapie' ],
		[ '2009-2011', 'Universität Wien' ]
	];

	const cv2 = [
		[ 'seit 2017', 'bei Sportmedic' ],
		[ 'seit 2015', 'freiberufliche Tätigkeit' ],
		[ 'seit 2015', 'Franziskusspital Margareten' ]
	];

	const content = [
		[
			'',
			`Bereits als Jugendliche hat das Gebiet der Physiotherapie mein Interesse geweckt. Damals
    musste ich aufgrund Rückenschmerzen physiotherapeutische Behandlung in Anspruch nehmen. Die
    Behandlungsmethoden fand ich damals schon sehr interessant und bis heute hält die
    Faszination an, den Körper auf vielfältige Weise kräftigen und mittels adäquater
    Heilverfahren unterstützen zu können. In dieser Zeit wurde mir erstmals bewusst, inwiefern
    der Körper jeden Tag allein durch Alltagsbewegungen gefordert ist. Fällt ein Teil des
    Körpers aus, können diese sowie diverse Freizeitaktivitäten und Hobbies nicht mehr
    ausgeführt werden und die Lebensqualität leidet beträchtlich darunter.`
		],
		[
			'',
			`Aufgrund meiner persönlichen Erfahrung mit Funktionsstörungen des Bewegungsapparates sowie
        meinem Interesse für Medizin wurde mit klar, beruflich den Weg zur Physiotherapeutin
        einzuschlagen. Das Studium, wo ich auch meinen Mann kennen und lieben gelernt habe, schloss
        ich im Juni 2014 ab. Mit meinem Mann teile ich die Begeisterung für Bewegung und Sport sowie
        die Motivation der stetigen Weiterbildung.`
		],
		[
			'',
			`Mir ist es ein großes Anliegen, mit meinen Patient*innen gemeinsam geeignete
        Behandlungsmaßnahmen zu eruieren und individuelle Therapiewege einzuschlagen, um möglichst
        rasch den Bewegungsalltag wieder schmerzfrei aufnehmen zu können.`
		],
		[
			'What else ...',
			`In meiner Freizeit bewege ich mich gerne. Von Ballett über Skifahren bis Volleyball spielen
        und laufen versuche ich ein breites Spektrum an Bewegung in mein Leben zu bringen. Zudem
        reise ich liebend gern in ferne Länder, komme aber auch genauso gerne wieder nach Hause
        zurück.`
		]
	];

	return <Person person={dataJulia} img={personImage} cvs={[ cv, cv2 ]} content={content} />;
}

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "physios_31.jpg" }) {
			...PersonImage
		}
	}
`;

export default Julia;
